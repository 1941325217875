.dashboard-admin-screen {
  height: 100%;
  padding: 24px;
}

.striped-background {
  background-image: linear-gradient(
    135deg,
    #cc8a8a 25%,
    #f5cece 25%,
    #f5cece 50%,
    #cc8a8a 50%,
    #cc8a8a 75%,
    #f5cece 75%,
    #f5cece 100%
  );
  background-size: 56.57px 56.57px;
}

.selected-striped-background {
  background-image: linear-gradient(
    135deg,
    #f0f0f0 25%,
    #ced4f5 25%,
    #ced4f5 50%,
    #f0f0f0 50%,
    #f0f0f0 75%,
    #ced4f5 75%,
    #ced4f5 100%
  );
  background-size: 56.57px 56.57px;
}
