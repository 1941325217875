@import '../../colors.scss';

.login-wrap {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: #f7fafc;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.container.login .login-box {
  background-color: #fdfdfd;
  border-radius: 4px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.12);
  padding: 56px 48px;
  margin-bottom: 5rem;
}

.container.login .login-title {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;
}

.img-wrap {
  position: relative;
  width: 40%;
  left: 0%;
  margin-left: 24px;
  margin-top: 5rem;
  margin-bottom: 3rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.img-wrap .main-pic {
  width: 100%;
}

.login-form button {
  width: 100%;
}

.login-form input {
  width: 100%;
}

.loading-animation {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-animation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $primaryColor;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primaryColor transparent transparent transparent;
}

.loading-animation div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-animation div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-animation div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
