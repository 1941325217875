div.clickahoy-menubar {
  position: absolute;
  top: 48px;
  left: 0;
  bottom: 0;
  width: 280px;
  background-color: #fbfcfd;
  text-align: left;
  border-right: 1px solid #dedede;
  /*box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.13);*/
}

.clickahoy-menubar {
  a {
    text-decoration: none !important;
    color: black !important;
  }

  & ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  & ul li {
    margin: 0;
    padding: 12px 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & ul li.vehicle {
    padding: 6px 12px;
    line-height: 42px;
  }

  & ul li .vehicle-name {
    display: inline-block;
    max-width: 184px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & ul a.selected li {
    background-color: rgb(228, 228, 228);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  & ul li:hover {
    background-color: rgb(214, 214, 214);
    cursor: pointer;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  @media all and (max-width: 1680px) {
    & ul a.selected li {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    & ul li:hover {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  & ul li svg {
    margin-right: 12px;
  }

  & .unavailable {
    color: lightgray !important;
  }
}
