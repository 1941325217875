.vehicle-detail-component {
  height: 100%;
  padding: 24px;

  table {
    tr td {
      padding: 0 0.75rem 0 0.75rem;
    }
    th,
    td {
      padding: 8px; // Adjust padding as needed
      text-align: left; // Align text to the left for readability
    }
  }
}
