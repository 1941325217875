.vehicle-overview-component {
  height: 100%;
  padding: 24px;

  table {
    table-layout: fixed; // Ensure consistent width distribution
    width: 80%;

    tr td {
      padding: 0 0.75rem 0 0.75rem;
    }
    th,
    td {
      padding: 8px; // Adjust padding as needed
      text-align: left; // Align text to the left for readability
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 15%; // Set a specific width for the ID column
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 50%; // Set a specific width for the Vehicle Name column
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 35%; // Set a specific width for the Seaborne ID column
    }
  }
}
