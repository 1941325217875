.pixi-pipeline-component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & .pixi-pipeline-component-header {
    height: 88px;
    width: 100%;
    background-color: #202020;
    border-bottom: 1px solid rgb(37, 37, 37);
  }

  & .pixi-pipeline-component-content {
    width: 100%;
    height: calc(100% - 88px);
    position: relative;
  }

  & .canvas-container {
    height: 100%;
    width: calc(100% - 480px);
    padding: 0;
    margin: 0;
    position: absolute;
  }

  & .log-container {
    height: 100%;
    width: 480px;
    background-color: #202020;
    color: white;
    position: absolute;
    right: 0;
    padding-top: 24px;
  }
}
